import { User } from './models/user';

const user = User.buildUser({ id: 1 });
const user2 = User.buildUser({ name: 'json-server name', age: 4000000 });
user.on('isAuthorized', () => {
  console.log('isAuthorized event');
});
user.fetch(1);
user2.save();
// https://auth.whatmotivateslearning.com/oauth2/authorize?response_type=code&client_id=7c0r6fluhcf6e16oejec959lu1&redirect_uri=https://notes.psdmedia.co.uk/index.html&state=someRandomState&scope=psdmedia-api/read_ring&code_challenge=R7dm4ds0sABRnlPSC_jDD-8ZoRFO0F7JdkIJF_dOnhc&code_challenge_method=S256

// https://auth.psdmedia.co.uk/oauth2/authorize?response_type=code&client_id=45o9oetrqniq67ggfa8j9vqgsb&redirect_uri=https://sc.psdmedia.co.uk/index.html&state=someRandomState&scope=psdmedia-api/read_ring&code_challenge=5VokoyL4kCF9BAruEgYjW2J9Jl-o-xmv7uOCctpcJ28&code_challenge_method=S256
